<template>
  <div class="sckz-table-container">
    <div class="table-btns">
      <a-button type="link" @click="dimesionVisible = true"><a-icon type="radar-chart" />维度</a-button>
      <a-button type="link" @click="handleCopy" :loading="downLoading"><a-icon type="download" />导出</a-button>
    </div>
    <div class="table-wrapper">
      <a-spin :spinning="isLoading">
        <el-table :key="tableKey" :data="tableData" style="width: 100%" border @sort-change="handleSortChange">
          <template v-for="(item, index) in currentColumns">
            <el-table-column
              :prop="item.prop"
              :key="item.key"
              :label="item.name"
              :width="item.width"
              :fixed="index === 0"
            >
              <template slot-scope="{ row }">
                <div
                  v-if="item.prop === dimesionEnum.creativeUrl.prop"
                  @click="clickCreative(row)"
                  class="creative-box"
                >
                  <img v-if="row.type === 1" :src="row.creativeUrl" :alt="row.title" />
                  <video v-else :src="row.creativeUrl" :controls="false"></video>
                </div>
                <template v-else-if="item.prop === dimesionEnum.date.prop">
                  {{ row.date }}
                </template>
                <div class="prop-box app-name" style="width: 130px" v-else-if="item.prop === dimesionEnum.appName.prop">
                  <IosSvg v-if="+row.os === 0" class="icon" />
                  <AndroidSvg v-if="+row.os === 1" class="icon" />
                  {{ row.appName }}
                </div>
                <div v-else-if="item.prop === dimesionEnum.placeName.prop" class="prop-box" style="width: 130px">
                  {{ row.placeName }}
                </div>
                <div v-else-if="item.prop === dimesionEnum.sourceName.prop" class="prop-box" style="width: 130px">
                  {{ row.sourceName }}
                </div>
                <div v-else-if="item.prop === dimesionEnum.platName.prop" class="prop-box" style="width: 130px">
                  {{ row.platName }}
                </div>
                <template v-else-if="item.prop === dimesionEnum.osName.prop">
                  {{ row.osName }}
                </template>
                <div v-else-if="item.prop === dimesionEnum.packageName.prop" class="prop-box" style="width: 130px">
                  {{ row.packageName }}
                </div>
                <div v-else-if="item.prop === dimesionEnum.productName.prop" class="prop-box" style="width: 130px">
                  {{ row.productName }}
                </div>
                <template v-else-if="item.prop === dimesionEnum.type.prop">
                  {{ row.type === 1 ? '图片' : '视频' }}
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column sortable="custom" :sort-orders="['descending', 'ascending', null]" prop="impress" label="展示" min-width="80">
            <template slot-scope="{ row }"> {{ numFormat(row.impress, 3, '') }}</template>
          </el-table-column>
          <el-table-column sortable="custom" :sort-orders="['descending', 'ascending', null]" prop="click" label="点击" min-width="80">
            <template slot-scope="{ row }"> {{ numFormat(row.click, 3, '') }}</template>
          </el-table-column>
          <el-table-column sortable="custom" :sort-orders="['descending', 'ascending', null]" prop="clickRate" label="点击率" min-width="100">
            <template slot-scope="{ row }"> {{ row.clickRate }}% </template>
          </el-table-column>
          <el-table-column sortable="custom" :sort-orders="['descending', 'ascending', null]" prop="income" label="预估收益" min-width="120">
            <template slot-scope="{ row }"> {{ numFormat(row.income, 3, '') }}</template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['descending', 'ascending', null]"
            prop="estimatedRevenueEcpm"
            label="预估Ecpm"
            min-width="120"
          >
            <template slot-scope="{ row }"> {{ row.estimatedRevenueEcpm }}</template>
          </el-table-column>
        </el-table>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 1"
          show-size-changer
          :current="query.page"
          :total="total"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="query.limit"
        />
      </a-spin>
    </div>
    <CustDimension
      v-if="dimesionVisible"
      :visible="dimesionVisible"
      :allDimesion="allDimesion"
      :groupByList="query.groupByList"
      @modalCancel="dimesionVisible = false"
      @changeCustDimension="changeCustDimension"
      :submitBack="dimesionBack"
    />
    <CreativeDetailModal
      v-if="detailVisible"
      :visible="detailVisible"
      :creativeInfo="creativeInfo"
      @modalCancel="detailVisible = false"
    />
  </div>
</template>

<script>
import CustDimension from '@/views/reportdatas/SynthReport/SynthTable/CustDimension'
import CreativeDetailModal from '../components/creativeDetailModal'
import { dimesionList, dimesionEnum } from './dimesionList'
import { creativeCZReport } from '@/api/originLity'
import excel from '@/utils/excel'
import request from '@/utils/request'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { numFormat } from '@/utils/dealNumber'
export default {
  name: 'SCKZTable',
  components: { CustDimension, CreativeDetailModal, IosSvg, AndroidSvg },
  data () {
    return {
      // 维度枚举
      dimesionEnum,
      // 是否显示素材弹窗
      detailVisible: false,
      // 素材详情数据
      creativeInfo: {},
      // 所有维度列表
      allDimesion: JSON.parse(JSON.stringify(dimesionList)),
      tableKey: '1',
      // 是否显示维度弹窗
      dimesionVisible: false,
      // 查询参数
      query: {
        page: 1,
        limit: 10,
        groupByList: [dimesionEnum.date.value, dimesionEnum.creativeUrl.value],
        sortOrder: undefined,
        sortBy: undefined
      },
      // 数据总数
      total: 0,
      // 表单数据
      tableData: [],
      // 下载loading
      downLoading: false,
      // 表单loading
      isLoading: false,
      // 导出参数暂存对象
      tempQuery: {},
      // 默认维度
      currentColumns: [
        {
          key: dimesionEnum.date.value,
          name: dimesionEnum.date.name,
          checked: false,
          prop: dimesionEnum.date.prop,
          width: dimesionEnum.date.width
        },
        {
          key: dimesionEnum.creativeUrl.value,
          name: dimesionEnum.creativeUrl.name,
          checked: false,
          prop: dimesionEnum.creativeUrl.prop,
          width: dimesionEnum.creativeUrl.width
        }
      ]
    }
  },
  methods: {
    numFormat,
    handleSortChange ({ column, prop, order }) {
      if (order === 'ascending') {
        this.query.sortOrder = 'asc'
      } else if (order === 'descending') {
        this.query.sortOrder = 'desc'
      } else {
        this.query.sortOrder = undefined
      }
      this.query.sortBy = this.query.sortOrder ? prop : undefined
      this.query.page = 1
      this.getTableData()
    },
    // 处理导出
    async handleCopy () {
      this.downLoading = true
      const baseCodeList = ['impress', 'click', 'clickRate', 'income', 'estimatedRevenueEcpm']
      const baseNameList = ['展示', '点击', '点击率', '预估收益', '预估Ecpm']
      const query = { ...this.tempQuery }
      const dimesionCodeList = []
      const dimesionNameList = []
      this.currentColumns.forEach((item) => {
        dimesionCodeList.push(item.prop)
        dimesionNameList.push(item.name)
      })
      query.codeList = [...dimesionCodeList, ...baseCodeList]
      query.nameList = [...dimesionNameList, ...baseNameList]
      try {
        const res = await this.exportToExcel(query)
        await excel(res, `素材快照.xlsx`)
        this.downLoading = false
      } catch (e) {
        console.log(e, '导出excel错误信息')
      } finally {
        this.downLoading = false
      }
    },
    // 获取到出报表数据
    exportToExcel (query) {
      return request.post('/creativeSS/downloadReport', query, {
        responseType: 'blob'
      })
    },
    // 素材详情点击
    clickCreative (row) {
      this.creativeInfo = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    },
    // 自定义维度确定
    changeCustDimension (dragArr) {
      this.currentColumns = JSON.parse(JSON.stringify(dragArr))
      this.query.groupByList = dragArr.map((item) => item.key)
      this.tableKey = new Date().getTime().toString()
      this.query.page = 1
      this.query.sortBy = undefined
      this.query.sortOrder = undefined
      this.getTableData()
    },
    // 自定义维度确定回调
    dimesionBack (arr) {
      if (arr.length === 0) {
        this.$message.warning('请至少选择一个维度')
        return false
      }
      return true
    },
    /**
     * 供父级调用查询
     * @param {Object} query 查询条件
     */
    dealQuery (query) {
      this.query = {
        ...this.query,
        ...query,
        page: 1,
        sortOrder: undefined,
        sortBy: undefined
      }
      this.getTableData()
    },
    // 修改page
    handleChangePage (page) {
      this.query.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTableData()
    },
    // 获取数据
    async getTableData () {
      this.isLoading = true
      // 获取表格数据
      const { data = {} } = await creativeCZReport(this.query)
      const { total = 0, items = [] } = data
      this.total = total
      this.tableData = items
      this.isLoading = false
      this.tempQuery = JSON.parse(JSON.stringify(this.query))
    }
  }
}
</script>
<style lang="less" scoped>
.sckz-table-container {
  margin-top: 10px;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  .table-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .table-wrapper {
    // .app-name {
    //   display: flex;
    //   align-items: center;
      .icon {
        width: 14px;
        height: 14px;
      }
    // }
    .creative-box {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100px;
      width: 200px;
      background-color: #f1f1f1;
      border-radius: 3px;
      cursor: pointer;
      img,
      video {
        cursor: pointer;
        max-width: 200px;
        max-height: 100px;
      }
    }
    .prop-box {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .pagination {
      padding: 10px 0;
    }
  }
}
</style>
